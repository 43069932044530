// eCommerce entrypoint - determine used tag manager
/* eslint-disable babel/camelcase */

const ecommerceName = (item, subscriptionTypeTitle) => {
  if (!item) {
    return '';
  } else if (item.type === 'SubscriptionFormula') {
    return subscriptionTypeTitle?.replaceAll('&nbsp;', '').replaceAll('\n', '');
  }

  return item.title?.replaceAll('&nbsp;', '').replaceAll('\n', '');
};

const ecommerceVariant = item => {
  if (item && item.type === 'SubscriptionFormula') {
    if (item.externalReference) {
      return item.externalReference;
    } else if (item.name) {
      return item.name;
    }

    return item.title?.replaceAll('&nbsp;', '').replaceAll('\n', '');
  }

  return '';
};

export const ecommerceGetProductUnitPrice = item => {

  if (item.projectedLifetimeValue) {
    return item.projectedLifetimeValue;
  }

  const paymentRequirement = item?.paymentRequirement;
  const paymentRequirementAmount = paymentRequirement?.amount;
  const isPaymentRecurring = paymentRequirement?.recurringPaymentOptions && paymentRequirement?.recurringPaymentOptions.length > 0;

  if (
    paymentRequirementAmount &&
    item &&
    item.duration &&
    MH_BRAND === BRANDS.DL &&
    isPaymentRecurring
  ) {
    return paymentRequirementAmount * item.duration;
  }

  return paymentRequirementAmount || 0;
};

export const ecommerceSetDataForProductView = (
  offerItems,
  subscriptionTypeTitle,
) => {
  const products = offerItems.map(item => ({
    id: item.id,
    name: ecommerceName(item, subscriptionTypeTitle),
    brand: item.brand,
    category: item.type,
    variant: ecommerceVariant(item),
    price: ecommerceGetProductUnitPrice(item),
    currency: 'EUR',
    position: item.sequenceIndex + 1,
  }));

  return {
    type: 'event',
    event: 'product_view',
    products,
  };
};

export const ecommerceRemoveDataForProductView = () => {
  return {
    type: 'event',
    event: 'ecommerce_clear',
  };
};

export const ecommerceSetDataForProductClick = (
  offerItems,
  subscriptionTypeTitle,
) => {
  const products = offerItems.map(item => ({
    id: item.id,
    name: ecommerceName(item, subscriptionTypeTitle),
    brand: item.brand,
    category: item.type,
    variant: ecommerceVariant(item),
    price: ecommerceGetProductUnitPrice(item),
    currency: 'EUR',
    position: item.sequenceIndex + 1,
  }));

  return {
    type: 'event',
    event: 'product_click',
    products,
  };
};

export const ecommerceSetDataForCheckout = (
  offerItems,
  subscriptionTypeTitle,
  step = 1,
) => {
  const products = offerItems.map(item => ({
    id: item.id,
    name: ecommerceName(item, subscriptionTypeTitle),
    variant: ecommerceVariant(item),
    price: ecommerceGetProductUnitPrice(item),
    currency: 'EUR',
    brand: item.brand,
    category: item.type,
    quantity: 1,
    position: item.sequenceIndex + 1,
  }));

  return {
    type: 'event',
    event: 'checkout',
    checkout_step: step,
    products,
  };
};

export const ecommerceRemoveDataForCheckout = () => {
  return {
    type: 'event',
    event: 'ecommerce_clear',
  };
};

export const ecommerceSetDataForCheckoutOption = (step, option) => {
  return {
    type: 'event',
    event: 'checkout_option',
    checkout_step: step,
    checkout_option: option,
  };
};

export const ecommerceRemoveDataForCheckoutOption = () => {
  return {
    type: 'event',
    event: 'ecommerce_clear',
  };
};

export const ecommerceSetDataForPurchase = (
  offerItems,
  subscriptionTypeTitle,
  orderData,
) => {
  const products = offerItems.map(item => ({
    name: ecommerceName(item, subscriptionTypeTitle),
    variant: ecommerceVariant(item),
    id: item.id,
    price: ecommerceGetProductUnitPrice(item),
    currency: 'EUR',
    brand: item.brand,
    category: item.type,
    quantity: 1,
    position: item.sequenceIndex + 1,
  }));

  return {
    type: 'event',
    event: 'purchase',
    order_id: orderData?.id,
    affiliation: 'aboshop',
    currency: 'EUR',
    revenue: ecommerceGetProductUnitPrice(offerItems?.[0]),
    products,
  };
};

export const ecommerceRemoveDataForPurchase = () => {
  return {
    type: 'event',
    event: 'ecommerce_clear',
  };
};
