import {
  getIdToken,
  getAccessToken,
  getDecodedAccessToken,
  getDecodedIdToken,
  getUserInfo,
  getFullUserProfile,
  refreshAccessToken,
  isAuthenticated,
  isEmailVerified,
  showEmailConfirmation,
  showLoginWall,
  showIdentityWall,
  showSilentLoginNotification,
  sendEmailVerification,
  getUserSubscriptions,
  hasIdentityLevel,
  on,
  off,
  getLoginUrl,
  getRegisterUrl,
} from './auth0-sdk';

(() => {
  const queue = window.CIAM || [];
  window.CIAM = {
    push: (fn: () => void) => {
      fn();
    },
    getIdToken,
    getAccessToken,
    getUserInfo,
    getFullUserProfile,
    getDecodedIdToken,
    getDecodedAccessToken,
    refreshAccessToken,
    isAuthenticated,
    isEmailVerified,
    showEmailConfirmation,
    showLoginWall,
    showIdentityWall,
    showSilentLoginNotification,
    sendEmailVerification,
    getUserSubscriptions,
    getLoginUrl,
    getRegisterUrl,
    hasIdentityLevel,
    on,
    off,
  };

  if (Array.isArray(queue)) {
    queue.forEach((fn: () => void) => fn());
  }

  if (window?.location?.search?.includes('_sl=')) {
    const searchParams = new URLSearchParams(window.location.search);

    if (!document.getElementById('auth0_silent-login-root')) {
      var doc = document.createElement('div');
      doc.id = 'auth0_silent-login-root';
      document.body.appendChild(doc);
    }

    showSilentLoginNotification({
      clientId: searchParams.get('_sl'),
      el: document.getElementById('auth0_silent-login-root'),
      language: searchParams.get('_sl_ui_locales') || 'nl',
    });
  }

  if (window?.location?.search.includes('force_sl=1')) {
    const parsedUrl = new URL(window.location.href);
    parsedUrl.searchParams.delete('force_sl');
    window.history.replaceState(history.state, undefined, parsedUrl.toString());
  }
})();
