import {
  Box,
  Caption,
  Flex,
  Heading,
  LinkText,
  Paper,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { MultibrandBanner, RouteYouBanner } from '@mediahuis/subscriber-ui';
import { getI18n } from 'react-i18next';

import Label from '~/components/Label';
import { SETTLEMENT } from '~/constants';
import { useGlobalContext } from '~/context/global';
import { useOfferContext } from '~/context/offer';
import { capitalize } from '~/utils/capitalize';
import formatPrice from '~/utils/formatPrice';

import FeaturesList from './FeaturesList';
import { language } from './language';

const DetailSummaryMobile = ({
  editFormula,
  editAboLink,
  isGoBackDisabled,
  caption,
  testIdCaption,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const { checkoutFlow, order } = useGlobalContext();
  const { offerFormula, offerType } = useOfferContext();

  const isCheckoutSettlement = checkoutFlow === SETTLEMENT;
  const choiceLabel = isCheckoutSettlement
    ? translation.yourSubscription
    : translation.yourChoice;

  return (
    <Box width="full">
      <Paper elevation={2}>
        <Box py={5}>
          <Flex
            px={7}
            pb={5}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Paragraph color="colorGrey50">
                {capitalize(choiceLabel)}
              </Paragraph>
              <Heading
                level={4}
                data-testid="offerdetail-title"
                style={{
                  textTransform: MH_GROUP === 'BE' ? 'lowercase' : 'capitalize',
                }}
              >
                <MarkdownRender
                  source={
                    offerType?.title?.replace(/&nbsp;/g, ' ').trim() || ''
                  }
                />
              </Heading>
            </Box>
            <Caption textAlign="right" data-testid="offerdetail-price">
              <MarkdownRender
                source={formatPrice(
                  isCheckoutSettlement
                    ? order && order.amount
                    : offerFormula.priceSentence,
                )}
              />
            </Caption>
          </Flex>
          <Box mx={7}>
            {(order && MH_GROUP === 'BE') && (
              <>
                {(offerFormula?.paymentRequirement?.recurringPaymentOptions?.length > 0 && offerFormula?.priceType === "Promo") && (
                  <Box mb={5} textAlign="right">
                    {translation.silentRenewalWarning}
                  </Box>
                )}
                <Box mb={5} textAlign="right">
                  {translation.cancellationInformation}
                </Box>
              </>
            )}
            <Box pb={3}>
              <Caption color="colorGreyBase">
                {translation.whatDoYouGet}
              </Caption>
            </Box>
            {offerType && offerType.features && (
              <>
                <FeaturesList
                  dataTestId="offerdetail-features"
                  data={offerType.features}
                  incentiveDescription={offerFormula?.incentiveDescription}
                />
                {offerFormula.isMultiBrand && (
                  <MultibrandBanner
                    activeBrand={MH_BRAND}
                    style={{
                      left: '-32px',
                      marginTop: '12px',
                      position: 'relative',
                      width: 'calc(100% + 32px)',
                    }}
                    text={translation.multibrandTextMobile}
                  />
                )}
                {offerFormula.hasRouteYou && (
                  <RouteYouBanner
                    style={{
                      left: '-32px',
                      marginTop: '12px',
                      position: 'relative',
                      width: 'calc(100% + 32px)',
                    }}
                    text={translation.routeYouTextMobile}
                  />
                )}
              </>
            )}
            {offerFormula.label && (
              <Box mb={editFormula ? 5 : 0} data-testid="offerdetail-labels">
                <Label text={offerFormula.label} />
              </Box>
            )}
            {editFormula && !isGoBackDisabled && (
              <LinkText onClick={editAboLink} data-testid={testIdCaption}>
                <Flex alignItems="center">
                  <Caption level={2}>{caption}</Caption>
                </Flex>
              </LinkText>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailSummaryMobile;
