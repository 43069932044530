import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { SETTLEMENT } from '~/constants';
import { tracker } from '~/utils/tracking/tracker';
import mapOfferDuration from '~/utils/mapOfferDuration';
import handleExternalLink from '~/utils/handleExternalLink';
import { useOfferContext } from '~/context/offer';
import { useGlobalContext } from '~/context/global';
import DetailSummaryMobile from './DetailSummaryMobile';
import DetailSummaryDesktop from './DetailSummaryDesktop';
import DetailSummaryMobileSticky from './DetailSummaryMobileSticky';

const DetailSummary = ({
  editFormula = false,
  sticky,
  isLoading = false,
  goBackPath,
  isGoBackDisabled = false,
  caption,
  testIdCaption,
}) => {
  const history = useHistory();
  const mhtTracker = tracker();
  const { user, checkoutFlow } = useGlobalContext();
  const { offerType, offerFormula } = useOfferContext();

  const isCheckoutSettlement = checkoutFlow === SETTLEMENT;

  const editAboLink = () => {
    if (!isCheckoutSettlement && mhtTracker) {
      const { title: typeTitle } = offerType;
      const { durationType, duration } = offerFormula;

      mhtTracker
        .push(
          mhtTracker.trackingCalls.Check10.clickCheck10ChangeProduct(
            user,
            typeTitle,
            mapOfferDuration(duration, durationType),
          ),
        )
        .catch(error => error);
    }

    if (goBackPath) {
      handleExternalLink(goBackPath, history);
    } else {
      history.goBack();
    }
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isMobile ? (
        sticky ? (
          <DetailSummaryMobileSticky
            editFormula={editFormula}
            isLoading={isLoading}
            editAboLink={editAboLink}
            isGoBackDisabled={isGoBackDisabled}
            caption={caption}
            testIdCaption={testIdCaption}
          />
        ) : (
          <DetailSummaryMobile
            editFormula={editFormula}
            isLoading={isLoading}
            editAboLink={editAboLink}
            isGoBackDisabled={isGoBackDisabled}
            caption={caption}
            testIdCaption={testIdCaption}
          />
        )
      ) : (
        <DetailSummaryDesktop
          editFormula={editFormula}
          isLoading={isLoading}
          editAboLink={editAboLink}
          isGoBackDisabled={isGoBackDisabled}
          caption={caption}
          testIdCaption={testIdCaption}
        />
      )}
    </Fragment>
  );
};

export default DetailSummary;
