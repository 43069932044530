import {
  getOriginVitrine,
  getSearchGoToUrl,
  getSessionStorageGoToUrl,
} from '~/utils/constants';
import { RENEWAL } from '~/constants';

export const isGoBackDisabled = () =>
  (!getOriginVitrine() && !getSearchGoToUrl() && !getSessionStorageGoToUrl()) ||
  window.location.href.includes(RENEWAL);
