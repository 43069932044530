const getSiteConfiguration = (brand, apiPrefix) => {
  if (brand === 'az') {
    return {
      brand: 'az',
      code: 'az',
      description:
        'Jetzt unser Digital-Abo, Digital-Extra-Abo oder Komplett-Abo kennenlernen: Im Monatsabo, mit oder ohne Laufzeit, zum Verschenken, für Studenten oder im Probeabo schon ab 0,99 €.',
      ogDescription:
        'Jetzt unser Digital-Abo, Digital-Extra-Abo oder Komplett-Abo kennenlernen: Im Monatsabo, mit oder ohne Laufzeit, zum Verschenken, für Studenten oder im Probeabo schon ab 0,99 €.',
      ogImage: '',
      ogLocale: 'de_DE',
      ogSiteName: 'Medienhaus Aachen GmbH',
      ogTitle: 'Unsere Angebote kennenlernen | Aachener Zeitung',
      ogUrl: `https://${apiPrefix}aboshop.aachener-zeitung.de`,
      title: 'Unsere Angebote kennenlernen | Aachener Zeitung',
    };
  }
  if (brand === 'co') {
    return {
      title: 'Contacto Abonnement - Die portugiesische Zeitung Luxemburgs',
      code: 'co',
      brand: 'co',
      description:
        'Sie möchten die beste portugiesischsprachige Wochenzeitung Luxemburgs? Wir liefern Ihnen jede Woche die neueste Ausgabe nach Hause. Jetzt entdecken!',
      ogUrl: `https://${apiPrefix}aboshop.contacto.lu`,
      ogSiteName: 'Mediahuis Luxembourg S.A.',
      ogImage: '',
      ogLocale: 'de_DE',
    };
  }
  if (brand === 'dl') {
    return {
      title: 'De Limburger abonnementen | Lees vanaf 7,50 per maand',
      code: 'dl',
      brand: 'dl',
      description:
        'Neem nu een abonnement op De Limburger en blijf dagelijks op de hoogte van het laatste nieuws. Maak de klik met jouw wereld en blijf op de hoogte via je mobiel, tablet, desktop of de papieren krant. Bekijk meer over de 4 verschillende abonnementen. Je bent al abonnee vanaf € 7,50 per maand.',
      fbAdmin: '',
      fbAdmin2: '',
      FbAppId: '',
      fbPages: '',
      ogDescription:
        'Neem nu een abonnement op De Limburger en blijf dagelijks op de hoogte van het laatste nieuws. Maak de klik met jouw wereld en blijf op de hoogte via je mobiel, tablet, desktop of de papieren krant. Bekijk meer over de 4 verschillende abonnementen. Je bent al abonnee vanaf € 7,50 per maand.',
      ogImage:
        'https://markup.limburger.nl/extra/static/img/website/DL_OG_image.png',
      ogSiteName: 'De Limburger',
      ogTitle:
        'Abonnement De Limburger - Alle formules en prijzen - De Limburger',
      ogUrl: `https://${apiPrefix}aboshop.limburger.nl`,
    };
  }
  if (brand === 'ds') {
    return {
      title:
        'Abonnement De Standaard - Alle formules en prijzen - De Standaard',
      code: 'ds-live',
      brand: 'ds',
      description:
        'Kies uw abonnement en lees De Standaard op papier en/of digitaal. Helder beschreven feiten en inzichten, van politiek over economie tot cultuur en wetenschap.',
      fbAdmin: '1031588996',
      fbAdmin2: '100006909940815',
      FbAppId: '155215551216365',
      fbPages: '7133374462',
      ogDescription:
        'Kies uw abonnement en lees De Standaard op papier en/of digitaal. Helder beschreven feiten en inzichten, van politiek over economie tot cultuur en wetenschap.',
      ogImage: 'https://markup.standaard.be/extra/assets/img/dummy-social.gif',
      ogSiteName: 'De Standaard',
      ogTitle:
        'Abonnement De Standaard - Alle formules en prijzen - De Standaard',
      ogUrl: `https://${apiPrefix}aboshop.standaard.be`,
    };
  }
  if (brand === 'gva') {
    return {
      code: 'gva',
      brand: 'gva',
      title:
        'Abonnement Gazet van Antwerpen - Alle formules en prijzen - Gazet van Antwerpen',
      description:
        'Vergelijk abonnementsformules en kies voor papier en/of digitaal. Je dagelijkse portie grote nieuwsfeiten, regio- en sportnieuws.',
      fbAdmin: '1031588996',
      fbAdmin2: '100006909940815',
      FbAppId: '122351784513266',
      fbPages: '25170247890',
      ogDescription:
        'Vergelijk abonnementsformules en kies voor papier en/of digitaal. Je dagelijkse portie grote nieuwsfeiten, regio- en sportnieuws.',
      ogImage: 'https://markup.gva.be/extra/assets/img/dummy-social.gif',
      ogSiteName: 'Gazet van Antwerpen',
      ogTitle:
        'Abonnement Gazet van Antwerpen - Alle formules en prijzen - Gazet van Antwerpen',
      ogUrl: `https://${apiPrefix}aboshop.gva.be`,
    };
  }
  if (brand === 'hbvl') {
    return {
      code: 'hbvl',
      brand: 'hbvl',
      title: 'Aboshop - Het Belang van Limburg - Alle formules en prijzen',
      description:
        'Vergelijk abonnementsformules en kies voor papier en/of digitaal. Nieuws uit Limburg en ver daarbuiten. Grote nieuwsfeiten, regio- en sportnieuws.',
      fbAdmin: '1031588996',
      fbAdmin2: '100006909940815',
      FbAppId: '193878777324687',
      fbPages: '14619952769',
      ogDescription:
        'Vergelijk abonnementsformules en kies voor papier en/of digitaal. Nieuws uit Limburg en ver daarbuiten. Grote nieuwsfeiten, regio- en sportnieuws.',
      ogImage: 'https://markup.hbvl.be/extra/assets/img/dummy-social.gif',
      ogSiteName: 'Het Belang van Limburg',
      ogTitle:
        'Aboshop - Het Belang van Limburg - Alle formules en prijzen - Het Belang van Limburg',
      ogUrl: `https://${apiPrefix}aboshop.hbvl.be`,
    };
  }
  if (brand === 'lt') {
    return {
      title: 'Luxembourg Times - Comparison - Mediahuis Luxembourg S.A.',
      code: 'lt',
      brand: 'lt',
      description: 'Abo-Shop des Luxemburger Wort',
      ogUrl: `https://${apiPrefix}aboshop.luxtimes.lu`,
      ogSiteName: 'Mediahuis Luxembourg S.A.',
      ogImage:
        'https://abos.wort.lu/wp-content/uploads/2021/05/luxtimes_plus_yellow.png',
      ogLocale: 'en_US',
    };
  }
  if (brand === 'lw') {
    return {
      title: 'Alle Luxemburger Wort Abonnements auf einen Blick',
      code: 'lw',
      brand: 'lw',
      description:
        'Sie möchten das Luxemburger Wort als Druckausgabe, digital oder als Druckausgabe und digital? Hier finden Sie das perfekte Abo für Sie. Jetzt entdecken!',
      ogUrl: `https://${apiPrefix}aboshop.wort.lu`,
      ogSiteName: 'Mediahuis Luxembourg S.A.',
      ogImage: '',
      ogLocale: 'de_DE',
    };
  }
  if (brand === 'nb') {
    return {
      title:
        'Abonnement Het Nieuwsblad - Alle formules en prijzen - Het Nieuwsblad',
      code: 'nb',
      brand: 'nb',
      description:
        'Vergelijk abonnementsformules en kies voor papier en/of digitaal. Grote nieuwsfeiten, regio- en sportnieuws en consumentenadvies waar je écht wat aan hebt.',
      fbAdmin: '1031588996',
      fbAdmin2: '100006909940815',
      FbAppId: '152476521481961',
      fbPages: '37823307325',
      ogDescription:
        'Vergelijk abonnementsformules en kies voor papier en/of digitaal. Grote nieuwsfeiten, regio- en sportnieuws en consumentenadvies waar je écht wat aan hebt.',
      ogImage: 'https://markup.nieuwsblad.be/extra/assets/img/dummy-social.gif',
      ogSiteName: 'Het Nieuwsblad',
      ogTitle:
        'Abonnement Het Nieuwsblad - Alle formules en prijzen - Het Nieuwsblad',
      ogUrl: `https://${apiPrefix}aboshop.nieuwsblad.be`,
    };
  }
  if (brand === 'tc') {
    return {
      title: 'Télécran - Mediahuis Luxembourg S.A.',
      code: 'tc',
      brand: 'tc',
      description: 'Abo-Shop des Luxemburger Wort',
      ogUrl: `https://${apiPrefix}aboshop.telecran.lu`,
      ogSiteName: 'Mediahuis Luxembourg S.A.',
      ogImage: '',
      ogLocale: 'de_DE',
    };
  }

  return {};
};

module.exports = getSiteConfiguration;
