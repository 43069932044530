import {
  Button,
  Caption,
  Flex,
  Icon,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { Notification } from '@mediahuis/chameleon-theme-wl/icons';
import { getI18n } from 'react-i18next';

import { useAuthentication } from '~/authentication';
import { Bold, Switcher } from '~/components';

import { language } from './language';

const OfferBannerInline = ({ variant, onClick }) => {
  const { language: lang } = getI18n();
  const translation = language[lang][variant];

  const { userInfo } = useAuthentication();

  return (
    <Switcher
      limit={2}
      space="1rem"
      threshold="40rem"
      style={{
        alignItems: 'center',
        backgroundColor: 'var(--color-grey-10)',
        border: '2px solid var(--color-marketing-base)',
        borderRadius: 'var(--border-radius-6)',
        padding: '1rem',
      }}
    >
      <Flex style={{ flexGrow: 2, gap: '1rem' }}>
        <Icon as={Notification} size="medium" />

        <Paragraph>
          <Bold>{userInfo?.firstName}</Bold>
          {translation.inlineText}
        </Paragraph>
      </Flex>

      <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
        <Button onClick={onClick}>{translation.inlineButton}</Button>
        <Caption level={2} textAlign="center">
          {translation.inlineCaption}
        </Caption>
      </Flex>
    </Switcher>
  );
};

export default OfferBannerInline;
