const base = {
  [LANG.NL]: {
    renewal: {
      dialogButton: 'Bekijk persoonlijk aanbod',
      dialogCaption: 'Hernieuw abonnement',
      dialogText: [
        'Welkom terug, ',
        '. Omdat je abonnee bent, staat er voor jou een persoonlijk hernieuwingsaanbod klaar.',
      ],
      inlineButton: 'Bekijk persoonlijk aanbod',
      inlineCaption: 'Hernieuw abonnement',
      inlineText:
        ', er staat een persoonlijk hernieuwingsaanbod voor je klaar.',
    },
    winback: {
      dialogButton: 'Bekijk persoonlijk aanbod',
      dialogCaption: 'Ontdek jouw welkom-terug-tarief',
      dialogText: [
        'Fijn je weer te zien, ',
        '. Omdat je eerder abonnee was staat er voor jou een persoonlijk welkom-terug-aanbod klaar.',
      ],
      inlineButton: 'Bekijk persoonlijk aanbod',
      inlineCaption: 'Ontdek jouw welkom-terug-tarief',
      inlineText:
        ', er staat een persoonlijk welkom-terug-aanbod voor je klaar.',
    },
  },
  [LANG.DE]: {
    renewal: {
      dialogButton: 'Personalisiertes Angebot anzeigen',
      dialogCaption: 'Abonnement erneuern',
      dialogText: [
        'Willkommen zurück, ',
        '. Da Sie ein Abonnent sind, erwartet Sie ein personalisiertes Verlängerungsangebot.',
      ],
      inlineButton: 'Personalisiertes Angebot anzeigen',
      inlineCaption: 'Abonnement erneuern',
      inlineText: ', wartet ein persönliches Verlängerungsangebot auf Sie.',
    },
    winback: {
      dialogButton: 'Personalisiertes Angebot anzeigen',
      dialogCaption: 'Entdecken Sie Ihre Willkommens-zurück-Rate',
      dialogText: [
        'Schön, Sie wiederzusehen ',
        '. Da Sie bereits ein Abonnent waren, erwartet Sie ein persönliches Willkommensangebot.',
      ],
      inlineButton: 'Personalisiertes Angebot anzeigen',
      inlineCaption: 'Entdecken Sie Ihre Willkommens-zurück-Rate',
      inlineText: ', ein persönliches Willkommensangebot auf Sie wartet.',
    },
  },
  [LANG.EN]: {
    renewal: {
      dialogButton: 'View personalised offer',
      dialogCaption: 'Renew subscription',
      dialogText: [
        'Welcome back, ',
        '. Since you are a subscriber, a personalised renewal offer is waiting for you.',
      ],
      inlineButton: 'View personalised offer',
      inlineCaption: 'Renew subscription',
      inlineText: ', a personal renewal offer is waiting for you.',
    },
    winback: {
      dialogButton: 'View personalised offer',
      dialogCaption: 'Discover your welcome-back-rate',
      dialogText: [
        'Good to see you again, ',
        '. Because you were a subscriber previously, a personal welcome-back-offer is waiting for you.',
      ],
      inlineButton: 'View personalised offer',
      inlineCaption: 'Discover your welcome-back-rate',
      inlineText: ', a personalised welcome-back-offer is waiting for you.',
    },
  },
  [LANG.FR]: {
    renewal: {
      dialogButton: "Voir l'offre personnalisée",
      dialogCaption: "Renouveler l'abonnement",
      dialogText: [
        'Bienvenue à nouveau, ',
        '. Puisque vous êtes abonné, une offre de renouvellement personnalisée vous attend.',
      ],
      inlineButton: "Voir l'offre personnalisée",
      inlineCaption: "Renouveler l'abonnement",
      inlineText: ', une offre personnelle de renouvellement vous attend.',
    },
    winback: {
      dialogButton: "Voir l'offre personnalisée",
      dialogCaption: "Découvrez votre taux d'accueil",
      dialogText: [
        'Heureux de vous revoir, ',
        '. Parce que vous étiez déjà abonné, une offre de bienvenue personnelle vous attend.',
      ],
      inlineButton: "Voir l'offre personnalisée",
      inlineCaption: "Découvrez votre taux d'accueil",
      inlineText: ', une offre de bienvenue personnalisée vous attend.',
    },
  },
};

export default base;
