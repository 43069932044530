import {
  Box,
  Caption,
  Divider,
  Flex,
  Heading,
  LinkText,
  Loader,
  Paper,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { MultibrandBanner, RouteYouBanner } from '@mediahuis/subscriber-ui';
import { getI18n } from 'react-i18next';

import Label from '~/components/Label';
import { SETTLEMENT } from '~/constants';
import { useGlobalContext } from '~/context/global';
import { useOfferContext } from '~/context/offer';
import { capitalize } from '~/utils/capitalize';
import formatPrice from '~/utils/formatPrice';

import FeaturesList from './FeaturesList';
import { language } from './language';

const DetailSummary = ({
  isLoading,
  editFormula,
  editAboLink,
  isGoBackDisabled,
  caption,
  testIdCaption,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const { checkoutFlow, order } = useGlobalContext();
  const { offerFormula, offerType } = useOfferContext();

  const isCheckoutSettlement = checkoutFlow === SETTLEMENT;
  const choiceLabel = isCheckoutSettlement
    ? translation.yourSubscription
    : translation.yourChoice;

  return (
    <Box pb={6} pt={2} top={4} position="sticky">
      <Paper elevation={2}>
        <Box p={7}>
          <Flex justifyContent="space-between" alignItems="center">
            <Paragraph color="colorGrey50">{capitalize(choiceLabel)}</Paragraph>
          </Flex>
          <Flex mb={4} justifyContent="space-between" alignItems="center">
            <Heading
              level={3}
              data-testid="offerdetail-title"
              style={{
                textTransform: MH_GROUP === 'BE' ? 'lowercase' : 'capitalize',
              }}
            >
              <MarkdownRender
                source={offerType?.title?.replace(/&nbsp;/g, ' ').trim() || ''}
              />
            </Heading>
            <Caption textAlign="right" my={3} data-testid="offerdetail-price">
              <MarkdownRender
                source={formatPrice(
                  isCheckoutSettlement
                    ? order && order.amount
                    : offerFormula.priceSentence,
                )}
              />
            </Caption>
          </Flex>
          {(order && MH_GROUP === 'BE') && (
            <>
              <Box pb={5}>
                <Divider />
              </Box>
              {(offerFormula?.paymentRequirement?.recurringPaymentOptions?.length > 0 && offerFormula?.priceType === "Promo") && (
                <Box mb={5} textAlign="right">
                  {translation.silentRenewalWarning}
                </Box>
              )}
              <Box mb={5} textAlign="right">
                {translation.cancellationInformation}
              </Box>
            </>
          )}
          {isLoading ? (
            <Box pt={5} pb={7}>
              <Loader />
            </Box>
          ) : (
            offerType &&
            offerType.features && (
              <div>
                <Box pb={5}>
                  <Divider />
                </Box>
                <FeaturesList
                  dataTestId="offerdetail-features"
                  data={offerType.features}
                  incentiveDescription={offerFormula?.incentiveDescription}
                />
                {offerFormula.isMultiBrand && (
                  <MultibrandBanner
                    activeBrand={MH_BRAND}
                    style={{
                      left: '-32px',
                      marginTop: '12px',
                      position: 'relative',
                      width: 'calc(100% + 32px)',
                    }}
                    text={translation.multibrandText}
                  />
                )}
                {offerFormula.hasRouteYou && (
                  <RouteYouBanner
                    style={{
                      left: '-32px',
                      marginTop: '12px',
                      position: 'relative',
                      width: 'calc(100% + 32px)',
                    }}
                    text={translation.routeYouText}
                  />
                )}

                <Box py={4}>
                  <Divider />
                </Box>
              </div>
            )
          )}
          {offerFormula.label && (
            <Box mb={editFormula ? 5 : 0} data-testid="offerdetail-labels">
              <Label text={offerFormula.label} />
            </Box>
          )}
          {editFormula && !isGoBackDisabled && (
            <LinkText onClick={editAboLink} data-testid={testIdCaption}>
              <Flex alignItems="center">
                <Caption level={2}>{caption}</Caption>
              </Flex>
            </LinkText>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailSummary;
