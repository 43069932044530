import noop from '~/utils/noop';
import { parseUtmParameters } from '~/utils/tracking/utmParameters';
import {
  ecommerceSetDataForProductView,
  ecommerceRemoveDataForProductView,
} from '~/utils/tracking/eCommerce';
import { tracker } from '~/utils/tracking/tracker';

const offerDetailTracking = ({
  staticRender,
  offerItems,
  user,
  offerTitle,
}) => {
  const mhtTracker = tracker();

  if (staticRender) {
    return noop();
  }
  async function fireTrackingCalls() {
    try {
      await mhtTracker.push(ecommerceSetDataForProductView(offerItems || [], offerTitle));
      await mhtTracker.push(
        mhtTracker.trackingCalls.pageview(user, false, parseUtmParameters()),
      );
      await mhtTracker.push(ecommerceRemoveDataForProductView());
      await mhtTracker.push(
        mhtTracker.trackingCalls.Cof.showCof(user, offerTitle),
      );
    } catch (error) {
      return error;
    }

    return true;
  }
  fireTrackingCalls();

  return () => {
    if (mhtTracker) {
      mhtTracker.push(mhtTracker.messages.stopHeartbeat).catch(error => error);
    }
  };
};

export default offerDetailTracking;
