import { List, ListItem, Text } from '@mediahuis/chameleon-react';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { Checkmark } from '@mediahuis/chameleon-theme-wl/icons';

const FeaturesList = ({ dataTestId, data, incentiveDescription, style }) => (
  <List data-testid={dataTestId}>
    {data.map(feature => (
      <ListItem
        icon={Checkmark}
        key={feature.id}
        iconColor="green50"
        data-testid={`offerdetail-feature-${feature.id}`}
      >
        <Text style={{ display: 'inline-block', ...style }} as="p">
          <MarkdownRender source={feature.description} />
        </Text>
      </ListItem>
    ))}
    {incentiveDescription && (
      <ListItem
        icon={Checkmark}
        iconColor="green50"
        data-testid="offerdetail-feature-incentive"
      >
        <Text style={{ display: 'inline-block', ...style }} as="p">
          <MarkdownRender source={`**+** ${incentiveDescription || ''}`} />
        </Text>
      </ListItem>
    )}
  </List>
);

export default FeaturesList;
