import {
  Button,
  Caption,
  Flex,
  Icon,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { Notification } from '@mediahuis/chameleon-theme-wl/icons';
import { getI18n } from 'react-i18next';

import { useAuthentication } from '~/authentication';
import { Bold } from '~/components';

import { language } from './language';

const OfferBannerDialog = ({ variant, onClick }) => {
  const { language: lang } = getI18n();
  const translation = language[lang][variant];

  const { userInfo } = useAuthentication();

  return (
    <Flex flexDirection="column" style={{ gap: '1.5rem' }}>
      <Flex style={{ gap: '1rem' }}>
        <Icon as={Notification} size="medium" />
        <Paragraph>
          {translation.dialogText[0]}
          <Bold>{userInfo?.firstName}</Bold>
          {translation.dialogText[1]}
        </Paragraph>
      </Flex>

      <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
        <Button onClick={onClick}>{translation.dialogButton}</Button>
        <Caption level={2} textAlign="center">
          {translation.dialogCaption}
        </Caption>
      </Flex>
    </Flex>
  );
};

export default OfferBannerDialog;
