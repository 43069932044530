import { Text } from '@mediahuis/chameleon-react';
import { fontSize3XLarge, fontWeightBold } from '@mediahuis/chameleon-theme-wl';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { getI18n } from 'react-i18next';
import styled from 'styled-components';
import { brandConfig } from './config';

import { language } from '../language';

const PriceMarkdown = styled.span`
  strong {
    font-weight: ${fontWeightBold};
    font-size: ${fontSize3XLarge};
  }
`;

const resolveFeatureText = (feature, isAvailable) => {
  if (isAvailable && feature.description) {
    return feature.description;
  }
  if (!isAvailable) {
    if (feature.negativeDescription) {
      return feature.negativeDescription;
    }
    if (feature.description) {
      return feature.description;
    }
  }

  return '';
};

const createDefaultVitrineCardLuxProps = (offerItem, combinedFeatures) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];

  const features = combinedFeatures.map(feature => {
    const isAvailable = !!offerItem.features?.find(
      itemFeature => itemFeature.id === feature.id,
    );

    return {
      children: (
        <Text alignSelf="center" fontFamily="system" size="Caption1">
          <MarkdownRender source={resolveFeatureText(feature, isAvailable)} />
        </Text>
      ),
      isAvailable,
      key: feature.id,
    };
  });

  const brandConfigItem = brandConfig.find(item => item.brand === MH_BRAND);

  return {
    buttonCaption: offerItem.extra || <span>&nbsp;</span>,
    buttonText: offerItem.buttonLabel
      ? offerItem.buttonLabel
      : `${translation.choose} ${
          MH_GROUP !== GROUPS.LUX ? offerItem.title : ''
        }`,
    description: offerItem.description && (
      <MarkdownRender source={offerItem.description} />
    ),
    features,
    highlight: offerItem.isHighlighted,
    highlightColor:
      brandConfigItem && brandConfigItem.highlightColor
        ? brandConfigItem.highlightColor
        : 'color-primary-base',
    imageSrc: offerItem.image ? offerItem.image : undefined,
    label: offerItem.label,
    priceCaption: offerItem.priceExtra && (
      <MarkdownRender source={offerItem.priceExtra} />
    ),
    priceText: offerItem.priceSentence && (
      <PriceMarkdown>
        <MarkdownRender source={offerItem.priceSentence} />
      </PriceMarkdown>
    ),
    title: offerItem.title && <MarkdownRender source={offerItem.title} />,
  };
};

export default createDefaultVitrineCardLuxProps;
