import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    // This timeout is needed for some reason
    // Directly calling window.scrollTo does not work, but not entirely sure why
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, []);
};
