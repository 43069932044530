const base = {
  [LANG.NL]: {
    backToOverview: 'Vorige',
    proceedToCheckout: 'Verder naar bestellen',
    changeFormula: 'Vorige',
    testIdChangeFormula: 'offerdetail-button-change-offer',
  },
  [LANG.DE]: {
    backToOverview: 'Zurück zur Übersicht',
    proceedToCheckout: 'Weiter zur Bestellung',
    changeFormula: 'Abonnement ändern',
    testIdChangeFormula: 'offerdetail-button-change-offer',
  },
  [LANG.EN]: {
    backToOverview: 'Back to overview',
    proceedToCheckout: 'Continue to order',
    changeFormula: 'Modify formula',
    testIdChangeFormula: 'offerdetail-button-change-offer',
  },
  [LANG.FR]: {
    backToOverview: "Retour à l'aperçu",
    proceedToCheckout: 'Continuer à commander',
    changeFormula: 'Changer la formule de lecture',
    testIdChangeFormula: 'offerdetail-button-change-offer',
  },
  [LANG.PT]: {
    backToOverview: 'Voltar ao menu geral',
    proceedToCheckout: 'Proceder para a encomenda',
    changeFormula: 'Modificar a opção',
    testIdChangeFormula: 'offerdetail-button-change-offer',
  },
};
export default base;
