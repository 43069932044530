import {
  GOTO,
  UTM_ARTICLE_ID,
  SUBSCRIPTION_ID,
  MULTI_BRAND,
} from '~/constants';
import {
  getSearchUtmArticleId,
  getSearchSubscriptionId,
  getSearchMultiBrand,
} from '~/utils/constants';

export const setSessionStorageData = ({ searchGoToUrl }) => {
  // Set UtmArticleId if exist to know if the checkout
  // flow started on Pormax or not.
  const searchUtmArticleId = getSearchUtmArticleId();
  if (searchUtmArticleId) {
    sessionStorage.setItem(UTM_ARTICLE_ID, searchUtmArticleId);
  }
  // Set UtmArticleId if exist to know if the checkout
  // flow started on Pormax or not.
  if (searchGoToUrl) {
    sessionStorage.setItem(GOTO, searchGoToUrl);
  }
  // Set subscription id and brand for multibrand logic.
  const searchSubscriptionId = getSearchSubscriptionId();
  const searchMultiBrand = getSearchMultiBrand();
  if (searchSubscriptionId) {
    sessionStorage.setItem(SUBSCRIPTION_ID, searchSubscriptionId);
    sessionStorage.setItem(MULTI_BRAND, searchMultiBrand);
  }
};
