import type { JWTPayload } from 'jose';

export function getCookie(cookieName: string): string | null {
  const cookies = document.cookie.split(';');
  for (const c of cookies) {
    const [name, ...value] = c.split('=');
    if (name.trim() === cookieName) {
      return value.join('=');
    }
  }
  return null;
}

export async function extractToken<T extends JWTPayload>(cookieName: string): Promise<T | null> {
  const cookie = getCookie(cookieName);

  if (!cookie) {
    return null;
  }

  const { decodeJwt } = await import('../vendor/jose');

  return decodeJwt(cookie) as T;
}
