import {
  CompactVitrineCard,
  MultibrandVitrineCard,
  RESPONSIVE_GRID_SCREEN_SIZE,
  ResponsiveGrid,
} from '@mediahuis/subscriber-ui';
import { useHistory } from 'react-router-dom';

import { UTM_KEY, CHECKOUT30_BACK_URL } from '~/constants';
import { useGlobalContext } from '~/context/global';
import {
  getQueryParamPrefix,
  getUrlWithSearchParams,
  handleExternalLink,
  isAbsoluteUrl,
} from '~/utils';
import {
  getSearchMultiBrand,
  getSearchSubscriptionId,
} from '~/utils/constants';
import { ecommerceSetDataForProductClick } from '~/utils/tracking/eCommerce';
import { tracker } from '~/utils/tracking/tracker';

import {
  DefaultVitrineCard,
  DefaultVitrineCardLux,
} from '../DefaultVitrineCard';
import {
  createCompactVitrineCardProps,
  createDefaultVitrineCardLuxProps,
  createDefaultVitrineCardProps,
  createMultibrandVitrineCardProps,
} from './util';

const VitrineGrid = ({ combinedFeatures, offerItems }) => {
  const { user } = useGlobalContext();
  const history = useHistory();

  const mhtTracker = tracker();

  const searchMultiBrand = getSearchMultiBrand();
  const searchSubscriptionId = getSearchSubscriptionId();

  const checkout30BackUrl = sessionStorage.getItem(CHECKOUT30_BACK_URL);

  const isMultibrandOffer = offerItems.some(
    offerItem => offerItem.isMultiBrand,
  );

  const redirectOffer = offerItem => {
    if (mhtTracker) {
      mhtTracker
        .push(mhtTracker.trackingCalls.chooseCotItem(user, offerItem.title))
        .catch(error => error);
      mhtTracker
        .push(ecommerceSetDataForProductClick([offerItem], offerItem.title))
        .catch(error => error);
    }

    let redirectUrl = offerItem.nextStep;
    // If absolute url pass utm params
    if (isAbsoluteUrl(redirectUrl)) {
      const utms = JSON.parse(sessionStorage.getItem(UTM_KEY));
      redirectUrl = getUrlWithSearchParams({ url: redirectUrl, params: { ...utms, checkout30BackUrl } });
    }
    // Add multibrand params to url if they exist which are then set in offerDetail.
    if (searchMultiBrand && searchSubscriptionId) {
      redirectUrl = `${redirectUrl}${getQueryParamPrefix(
        redirectUrl,
      )}subscriptionId=${searchSubscriptionId}&multiBrand=${searchMultiBrand}`;
    }

    handleExternalLink(redirectUrl, history);
  };

  return (
    <ResponsiveGrid
      columns={{
        xs: {
          count: 1,
        },
        sm: {
          count: 1,
        },
        md: {
          count: offerItems.length >= 2 ? 2 : 1,
          width: '325px',
        },
        lg: {
          count: offerItems.length >= 2 ? 2 : 1,
          width: '325px',
        },
        xl: {
          width: offerItems.length <= 3 ? '325px' : undefined,
        },
      }}
      gap="1rem"
      justifyContent="center"
      testId="vitrine"
      render={screenSize => (
        <>
          {offerItems.map(offerItem => {
            if (
              screenSize === RESPONSIVE_GRID_SCREEN_SIZE.xs ||
              screenSize === RESPONSIVE_GRID_SCREEN_SIZE.sm
            ) {
              return (
                <CompactVitrineCard
                  {...createCompactVitrineCardProps(offerItem)}
                  key={offerItem.id}
                  testId={`vitrine-card-${offerItem.id}`}
                  onClick={() => redirectOffer(offerItem)}
                />
              );
            }

            if (
              MH_BRAND === 'az' ||
              MH_BRAND === 'co' ||
              MH_BRAND === 'lt' ||
              MH_BRAND === 'lw' ||
              MH_BRAND === 'tc'
            ) {
              return (
                <DefaultVitrineCardLux
                  {...createDefaultVitrineCardLuxProps(
                    offerItem,
                    combinedFeatures,
                  )}
                  key={offerItem.id}
                  testId={`vitrine-card-${offerItem.id}`}
                  onClick={() => redirectOffer(offerItem)}
                />
              );
            }

            if (isMultibrandOffer && MH_GROUP === GROUPS.BE) {
              return (
                <MultibrandVitrineCard
                  {...createMultibrandVitrineCardProps(
                    offerItem,
                    combinedFeatures,
                  )}
                  key={offerItem.id}
                  testId={`vitrine-card-${offerItem.id}`}
                  onClick={() => redirectOffer(offerItem)}
                />
              );
            }

            return (
              <DefaultVitrineCard
                {...createDefaultVitrineCardProps(offerItem, combinedFeatures)}
                key={offerItem.id}
                testId={`vitrine-card-${offerItem.id}`}
                onClick={() => redirectOffer(offerItem)}
              />
            );
          })}
        </>
      )}
    />
  );
};

export default VitrineGrid;
