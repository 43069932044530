import { useState } from 'react';
import styled from 'styled-components';
import { Banner as ChBanner } from '@mediahuis/chameleon-react';
import { colorBlueBase } from '@mediahuis/chameleon-theme-wl';
import { MarkdownRender } from '@mediahuis/subscriber-react';

const Banner = styled(ChBanner)`
  a,
  a:hover {
    color: ${colorBlueBase};
    text-decoration: underline;
  }
`;

const Message = ({ type, message }) => {
  const [bannerShown, setBannerShown] = useState(!!type);

  return (
    <Banner
      show={bannerShown}
      appearance={type}
      onClose={() => setBannerShown(false)}
      mb={4}
    >
      {typeof message === 'string' ? (
        <MarkdownRender source={message} />
      ) : (
        message
      )}
    </Banner>
  );
};

export default Message;
