const mapOfferDuration = (amount = 1, durationType = '') => {
  switch (`${durationType}`.toLowerCase()) {
    case 'days':
      return `${amount}d`;
    case 'weeks':
      return `${amount}w`;
    case 'months':
      return `${amount}m`;
    case 'years':
      return `${amount * 12}m`;
    default:
      return 'unknown';
  }
};

export default mapOfferDuration;
