import mitt from 'mitt';

let emitterInstance: ReturnType<typeof mitt> | null = null;

export type Events = 'auth:session_changed' | 'auth:session_expired';

export function generateEventName(eventName: Events, clientId: string) {
  return `${eventName}:${clientId}`;
}

export function getEmitter() {
  if (!emitterInstance) {
    emitterInstance = mitt();
  }

  return emitterInstance;
}
