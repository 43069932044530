import { tracker } from '~/utils/tracking/tracker';
import { PARAM_FORMULA_ID } from '~/constants';
import { ecommerceSetDataForProductClick } from '~/utils/tracking/eCommerce';

export const handleToCheckout = ({
  user,
  offerTitle,
  offerFormula,
  history,
  defaultFlow,
}) => {
  const mhtTracker = tracker();

  if (mhtTracker) {
    mhtTracker
      .push(mhtTracker.trackingCalls.Cof.clickCofNextStep(user, offerTitle))
      .catch(error => error);
    mhtTracker
      .push(ecommerceSetDataForProductClick([offerFormula], offerTitle))
      .catch(error => error);
  }

  const redirectLink = `/checkout?${PARAM_FORMULA_ID}=${offerFormula.id}`;

  history.push(redirectLink, {
    checkoutFlow: defaultFlow,
  });
};
