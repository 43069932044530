/* eslint-disable import/max-dependencies */
import {
  Box,
  Button,
  Caption,
  Divider,
  Flex,
  Heading,
  Icon,
  LinkText,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { ChevronDown, ChevronUp } from '@mediahuis/chameleon-theme-wl/icons';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { MultibrandBanner, RouteYouBanner } from '@mediahuis/subscriber-ui';
import { useEffect, useState } from 'react';
import { isMobileSafari } from 'react-device-detect';
import { getI18n } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Bold } from '~/components/Bold';
import Label from '~/components/Label';
import StickyModal from '~/components/StickyModal';
import { DEFAULT, SETTLEMENT } from '~/constants';
import { useGlobalContext } from '~/context/global';
import { useOfferContext } from '~/context/offer';
import { capitalize } from '~/utils/capitalize';
import formatPrice from '~/utils/formatPrice';
import handleExternalLink from '~/utils/handleExternalLink';

import { handleToCheckout } from '../../Offer/OfferDetail/utils';
import FeaturesList from './FeaturesList';
import { language } from './language';

const Inline = styled.div`
  display: inline-block;
`;

const AnimatedContent = styled.div`
  transition: max-height ${props => (props.isOpen ? '.6s' : '.2s')} ease;
  max-height: ${props => (props.isOpen ? '100vh' : '0vh')};
`;

const DetailSummaryMobileSticky = ({
  editFormula,
  editAboLink,
  isGoBackDisabled,
  caption,
  defaultFlow = DEFAULT,
  testIdCaption,
}) => {
  const { language: selectedLanguage } = getI18n();
  const translation = language[selectedLanguage];
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { offerFormula, offerType } = useOfferContext();
  const { user, setUIState, checkoutFlow, order } = useGlobalContext();

  const isCheckoutSettlement = checkoutFlow === SETTLEMENT;
  const choiceLabel = isCheckoutSettlement
    ? translation.yourSubscription
    : translation.yourChoice;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const height = document.querySelector('#detailSummary-sticky').parentElement
      .offsetHeight;
    setUIState({ extraFooterHeight: height + (isMobileSafari ? 60 : 0) });
    return () => {
      setUIState({ extraFooterHeight: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StickyModal isOpen={isOpen} closeModal={() => setIsOpen(!isOpen)}>
      <Box mx={7} mt={6} mb={5} id="detailSummary-sticky">
        <Flex justifyContent="space-between" alignItems="center">
          <Paragraph color="colorGrey50">{capitalize(choiceLabel)}</Paragraph>
          {editFormula && !isGoBackDisabled && (
            <LinkText to="" onClick={editAboLink} data-testid={testIdCaption}>
              {caption}
            </LinkText>
          )}
        </Flex>
        <Flex mb={4} justifyContent="space-between" alignItems="center">
          <Heading
            level={4}
            style={{
              textTransform: MH_GROUP === 'BE' ? 'lowercase' : 'capitalize',
            }}
          >
            <MarkdownRender
              source={offerType?.title?.replace(/&nbsp;/g, ' ').trim() || ''}
            />
          </Heading>
          <Caption textAlign="right">
            <MarkdownRender
              source={formatPrice(
                isCheckoutSettlement
                  ? order && order.amount
                  : offerFormula.priceSentence,
              )}
            />
          </Caption>
        </Flex>
        {offerFormula.label && (
          <Box mb={editFormula ? 5 : 0} data-testid="offerdetail-labels">
            <Label text={offerFormula.label} />
          </Box>
        )}
        <Flex alignItems="center" onClick={() => toggleHandler()}>
          <Caption mr={3} color="colorPrimaryBase">
            <Bold>
              {isOpen ? translation.understood : translation.whatDoIGet}
            </Bold>
          </Caption>
          <Icon
            as={isOpen ? ChevronDown : ChevronUp}
            color="colorPrimaryBase"
            size="small"
          />
        </Flex>
      </Box>
      <AnimatedContent isOpen={isOpen}>
        <Box mx={7} mb={2}>
          {offerType && offerType.features && (
            <Inline>
              <Box pb={5}>
                <Divider />
              </Box>
              <FeaturesList
                dataTestId="offerdetail-features"
                data={offerType.features}
                incentiveDescription={offerFormula?.incentiveDescription}
              />
              {offerFormula.isMultiBrand && (
                <MultibrandBanner
                  activeBrand={MH_BRAND}
                  style={{
                    left: '-32px',
                    marginTop: '12px',
                    position: 'relative',
                    width: 'calc(100% + 32px)',
                  }}
                  text={translation.multibrandTextMobile}
                />
              )}
              {offerFormula.hasRouteYou && (
                <RouteYouBanner
                  style={{
                    left: '-32px',
                    marginTop: '12px',
                    position: 'relative',
                    width: 'calc(100% + 32px)',
                  }}
                  text={translation.routeYouTextMobile}
                />
              )}
              <Box py={5}>
                <Divider />
              </Box>
            </Inline>
          )}
        </Box>
        <Button
          data-testid="continue-button"
          width="full"
          style={{
            'border-radius': 0,
          }}
          onClick={() => {
            offerFormula.nextStep
              ? handleExternalLink(offerFormula.nextStep)
              : handleToCheckout({
                  user,
                  offerTitle: offerType.title,
                  offerFormula,
                  history,
                  defaultFlow,
                });
          }}
        >
          {translation.proceedToCheckout}
        </Button>
      </AnimatedContent>
    </StickyModal>
  );
};

export default DetailSummaryMobileSticky;
