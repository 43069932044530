/* eslint-disable import/max-dependencies */
import {
  Box,
  Button,
  Caption,
  Choice,
  Flex,
  Heading,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/icons';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { lazy, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getI18n } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { WrapperWithScrollToTopButton } from '~/components/WrapperWithScrollToTopButton';
import { DEFAULT, OFFER_DETAIL_PATH_NAME, UTM_KEY, CHECKOUT30_BACK_URL } from '~/constants';
import { BANNERS, WARNINGS } from '~/constants/messages';
import { useGlobalContext } from '~/context/global';
import { useOfferContext } from '~/context/offer';
import { capitalize } from '~/utils/capitalize';
import { getSearchGoToUrl, getCheckout30BackUrl } from '~/utils/constants';
import getHtmlContent from '~/utils/getHtmlContent';
import getOfferType from '~/utils/getOfferType';
import getUrlWithSearchParams from '~/utils/getUrlWithSearchParams';
import goHome from '~/utils/goHome';
import handleExternalLink from '~/utils/handleExternalLink';
import useScrollTop from '~/utils/useScrollTop';

import offerDetailTracking from '../../../utils/tracking/offerDetailTracking';
import DetailSummary from '../../components/DetailSummary';
import usePrevious from '../hooks/usePrevious';
import { language } from './language';
import {
  getGoBackPath,
  handleToCheckout,
  isGoBackDisabled,
  setSessionStorageData,
} from './utils';

const HtmlRenderer = lazy(() => import('~/components/HtmlRenderer'));

const OfferDetail = ({ defaultFlow = DEFAULT }) => {
  const { language: lang } = getI18n();
  const translation = language[lang];

  useScrollTop();
  const history = useHistory();
  const { offer, offerType, setOfferType, setOfferFormula } = useOfferContext();
  const { staticRender, setGlobalState } = useGlobalContext();

  const { user } = useGlobalContext();

  const [checked, setChecked] = useState(offer.items[0].id);
  const [offerHtmlContent, setOfferHtmlContent] = useState({});
  const offerFormula =
    offer.items && offer.items.find(element => element.id === checked);

  const prevOfferFormulaParent = usePrevious(offerFormula.parent);
  const searchGoToUrl = getSearchGoToUrl();

  const checkout30BackUrl = getCheckout30BackUrl();
  if (checkout30BackUrl) {
    sessionStorage.setItem(CHECKOUT30_BACK_URL, checkout30BackUrl);
  }

  // Handle all session storage logic
  useEffect(() => {
    setSessionStorageData({ searchGoToUrl });
    sessionStorage.setItem(OFFER_DETAIL_PATH_NAME, window.location.pathname);
  }, [searchGoToUrl, setGlobalState]);

  useEffect(() => {
    if (prevOfferFormulaParent !== offerFormula.parent) {
      getOfferType({
        parent: offerFormula.parent,
        onSuccess: response => {
          // sort features by sequenceIndex
          response.features.length > 1 &&
            response.features.sort((a, b) => a.sequenceIndex - b.sequenceIndex);
          setOfferType({ ...response });
          setOfferFormula({ ...offerFormula });
        },
        onError: () =>
          goHome(history, {
            message: {
              type: BANNERS.INFO,
              text: WARNINGS[lang]?.GENERAL,
            },
          }),
      });
    } else {
      setOfferFormula({ ...offerFormula });
    }
  }, [
    lang,
    history,
    offerFormula,
    prevOfferFormulaParent,
    setOfferFormula,
    setOfferType,
  ]);

  useEffect(() => {
    getHtmlContent({
      key: `${HTML_CONTENT_LOCATIONS.OFFER}_${offer.id}`,
      onSuccess: response => setOfferHtmlContent(response.contentLinks),
      onError: err => err,
    });
  }, [offer]);

  useEffect(() => {
    if (offerType?.id) {
      offerDetailTracking({
        staticRender,
        offerItems: offer.items,
        user,
        offerTitle: offerType.title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerType, staticRender]);

  return (
    <WrapperWithScrollToTopButton>
      {offerHtmlContent.top && (
        <Box>
          <HtmlRenderer
            offerHtmlContent={offerHtmlContent.top}
            position="top"
          />
        </Box>
      )}
      <Flex
        flexWrap={{
          xs: 'wrap',
          md: 'nowrap',
        }}
        justifyContent="space-between"
      >
        <Box mr={isMobile ? 0 : 10} pb={5} style={{ flex: 7 }} width="full">
          <Heading mb={7} level={3} data-testid="offer-title">
            {offer.title}
          </Heading>

          <Flex
            data-testid="offerformulas"
            flexDirection="column"
            style={{ gap: '0.5rem' }}
          >
            {offer.items &&
              offer.items.map(item => (
                <Choice
                  aria-checked={checked === item.id}
                  checked={checked === item.id}
                  data-testid={`offerformulas-choice-${item.id}`}
                  id={`${item.id}`}
                  info={<MarkdownRender source={capitalize(item.title)} />}
                  key={item.id}
                  message={<MarkdownRender source={item.extra} />}
                  name="choice"
                  title={<MarkdownRender source={item.priceSentence} />}
                  value={`${item.id}`}
                  onChange={() => setChecked(item.id)}
                >
                  <Paragraph>
                    <MarkdownRender source={item.description} />
                  </Paragraph>
                </Choice>
              ))}
          </Flex>

          <Flex mt={3}>
            {!isMobile && !isGoBackDisabled() && (
              <Box mr={3} flex={4}>
                <Button
                  appearance="plain"
                  data-testid="btn-back-home"
                  style={{ flex: '5' }}
                  width="full"
                  onClick={() => handleExternalLink(getGoBackPath(), history)}
                >
                  {translation.backToOverview}
                </Button>
              </Box>
            )}
            <Box flex={6}>
              <Button
                data-testid="btn-create-order"
                iconRight={ChevronForward}
                width="full"
                onClick={() => {
                  offerFormula.nextStep
                    ? handleExternalLink(
                        getUrlWithSearchParams({
                          url: offerFormula.nextStep,
                          params: JSON.parse(sessionStorage.getItem(UTM_KEY)),
                        }),
                      )
                    : handleToCheckout({
                        user,
                        offerTitle: offerType.title,
                        offerFormula,
                        history,
                        defaultFlow,
                      });
                }}
              >
                {translation.proceedToCheckout}
              </Button>
              {!isMobile && (
                <Box mt={3}>
                  <Caption color="colorGrey50" textAlign="center">
                    <MarkdownRender source={offer.extra} />
                  </Caption>
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
        {isMobile ? (
          <DetailSummary
            editFormula
            goBackPath={getGoBackPath()}
            isLoading={!offerType}
            sticky
            isGoBackDisabled={isGoBackDisabled()}
            caption={translation.changeFormula}
            defaultFlow={defaultFlow}
            testIdCaption={translation.testIdChangeFormula}
          />
        ) : (
          <Box style={{ flex: 4 }} ml={8} width="full">
            <DetailSummary
              editFormula
              goBackPath={getGoBackPath()}
              isLoading={!offerType}
              isGoBackDisabled={isGoBackDisabled()}
              caption={translation.changeFormula}
              testIdCaption={translation.testIdChangeFormula}
            />
          </Box>
        )}
      </Flex>
      {offerHtmlContent.bottom && (
        <Box mt={10}>
          <HtmlRenderer
            offerHtmlContent={offerHtmlContent.bottom}
            position="bottom"
          />
        </Box>
      )}
    </WrapperWithScrollToTopButton>
  );
};

export default OfferDetail;
