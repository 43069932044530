/* eslint-disable import/max-dependencies */
import {
  Box,
  Caption,
  Dialog,
  Heading,
  Paragraph,
} from '@mediahuis/chameleon-react';
import { MarkdownRender } from '@mediahuis/subscriber-react';
import { lazy, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { AUTHENTICATION_STATUS, useAuthentication } from '~/authentication';
import { Center } from '~/components';
import { OfferBanner } from '~/components/OfferBanner';
import { WrapperWithScrollToTopButton } from '~/components/WrapperWithScrollToTopButton';
import {
  CHECKOUT_FLOW,
  CHOSEN_PAYMENT_INTERVAL,
  GOTO,
  LAST_VALIDATED_STEP,
  MULTI_BRAND,
  OFFER_FORMULA_ID,
  ORDER_ID,
  ORIGIN_VITRINE,
  RENEWAL,
  RENEWAL_ID,
  SUBSCRIPTION_ID,
  VITRINE_PATHNAME,
  CHECKOUT30_BACK_URL
} from '~/constants';
import { useGlobalContext } from '~/context/global';
import { useOfferContext } from '~/context/offer';
import {
  capitalize,
  getCardFeatures,
  getHtmlContent,
  getRenewalOffer,
  noop,
} from '~/utils';
import {
  ecommerceRemoveDataForProductView,
  ecommerceSetDataForProductView,
} from '~/utils/tracking/eCommerce';
import { tracker } from '~/utils/tracking/tracker';
import { parseUtmParameters } from '~/utils/tracking/utmParameters';
import useScrollTop from '~/utils/useScrollTop';
import { getCheckout30BackUrl } from '~/utils/constants';

import { VitrineGrid } from './components/VitrineGrid';

const HtmlRenderer = lazy(() => import('~/components/HtmlRenderer'));

const OfferOverview = ({ defaultFlow }) => {
  const { authenticationStatus, userInfo } = useAuthentication();
  const { i18n } = useTranslation();
  useScrollTop();
  const mhtTracker = tracker();
  const history = useHistory();
  const location = useLocation();

  const { staticRender, setGlobalState } = useGlobalContext();
  const { offer, setOfferType, setOfferFormula } = useOfferContext();

  const [isOfferBannerDialogOpen, setIsOfferBannerDialogOpen] = useState(false);
  const [offerHtmlContent, setOfferHtmlContent] = useState({});
  const [renewalOffer, setRenewalOffer] = useState(null);

  const isRenewal = defaultFlow === RENEWAL;

  const checkout30BackUrl = getCheckout30BackUrl();
  if (checkout30BackUrl) {
    sessionStorage.setItem(CHECKOUT30_BACK_URL, checkout30BackUrl);
  }

  useEffect(() => {
    if (staticRender) {
      return noop();
    }

    async function fireTrackingCalls() {
      try {
        await mhtTracker.push(
          ecommerceSetDataForProductView(offer.items || [], ''),
        );
        await mhtTracker.push(
          mhtTracker.trackingCalls.pageview(
            userInfo,
            false,
            parseUtmParameters(),
          ),
        );
        await mhtTracker.push(ecommerceRemoveDataForProductView());
        await mhtTracker.push(mhtTracker.trackingCalls.showCot(userInfo));
      } catch (error) {
        return error;
      }
      return true;
    }

    if (
      authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED ||
      authenticationStatus === AUTHENTICATION_STATUS.UNAUTHENTICATED
    ) {
      fireTrackingCalls();
    }

    return () => {
      if (mhtTracker) {
        mhtTracker
          .push(mhtTracker.messages.stopHeartbeat)
          .catch(error => error);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus, staticRender]);

  useEffect(() => {
    // Set sessions storage item to indicate user has come through vitrine.
    // Necessary to know what to to on "goBack" functionality.
    if (location.pathname === VITRINE_PATHNAME) {
      sessionStorage.setItem(ORIGIN_VITRINE, '/');
    } else {
      sessionStorage.setItem(ORIGIN_VITRINE, location?.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isRenewalOffer = offer.type === 'renewal';
    const isWinbackOffer = offer.type === 'winback';

    if (
      authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED &&
      !isRenewalOffer &&
      !isWinbackOffer &&
      (MH_BRAND === 'ds' ||
        MH_BRAND === 'gva' ||
        MH_BRAND === 'hbvl' ||
        MH_BRAND === 'nb')
    ) {
      getRenewalOffer({
        accountId: userInfo?.accountGuid,
        onSuccess: response => {
          setIsOfferBannerDialogOpen(true);
          setRenewalOffer(response);
        },
      });
    }
  }, [offer.type, authenticationStatus, userInfo?.accountGuid]);

  useEffect(() => {
    getHtmlContent({
      key: `${HTML_CONTENT_LOCATIONS.OFFER}_${offer.id}`,
      onSuccess: response => setOfferHtmlContent(response.contentLinks),
      onError: err => err,
    });
  }, [offer, i18n.language]);

  const sortedOfferItems = useMemo(() => {
    if (offer.items) {
      if (isMobile) {
        return offer.items.sort((a, b) => {
          if (a.features.length < b.features.length) {
            return -1;
          }
          if (a.features.length > b.features.length) {
            return 1;
          }

          return 0;
        });
      }

      return offer.items;
    }

    return [];
  }, [offer.items]);

  useEffect(() => {
    sessionStorage.removeItem(GOTO);
    sessionStorage.removeItem(SUBSCRIPTION_ID);
    sessionStorage.removeItem(MULTI_BRAND);
    if (!isRenewal) {
      sessionStorage.removeItem(RENEWAL_ID);
    }
  }, [isRenewal]);

  useEffect(() => {
    sessionStorage.removeItem(ORDER_ID);
    sessionStorage.removeItem(OFFER_FORMULA_ID);
    sessionStorage.removeItem(LAST_VALIDATED_STEP);
    sessionStorage.removeItem(CHOSEN_PAYMENT_INTERVAL);
    if (!isRenewal) {
      sessionStorage.removeItem(CHECKOUT_FLOW);
      sessionStorage.removeItem(CHECKOUT30_BACK_URL);
    }

    setOfferType({});
    setOfferFormula({});

    setGlobalState({
      order: null,
      uploads: { pdf: null, image: null, file: null },
    });
  }, [isRenewal, setGlobalState, setOfferFormula, setOfferType]);

  const handleOfferBannerClick = () =>
    history.push(
      renewalOffer.slug
        ? `/${renewalOffer.slug}?utm_campaign=${renewalOffer.type === 'winback' ? 'winbackonvitrine' : 'renewalonvitrine'}&utm_source=${MH_BRAND}&utm_medium=${isOfferBannerDialogOpen ? 'popup' : 'banner'}` : '/',
    );

  return (
    <WrapperWithScrollToTopButton>
      {offerHtmlContent.top && (
        <Box>
          <HtmlRenderer
            offerHtmlContent={offerHtmlContent.top}
            position="top"
          />
        </Box>
      )}

      {!isOfferBannerDialogOpen && renewalOffer && (
        <Center max="60rem" style={{ paddingBottom: '1%', paddingTop: '1%' }}>
          <OfferBanner
            layout="inline"
            variant={renewalOffer.type === 'winback' ? 'winback' : 'renewal'}
            onClick={handleOfferBannerClick}
          />
        </Center>
      )}

      <Box textAlign="center" mt={5} mb={5}>
        <Heading level={3} fontFamily="primary">
          {capitalize(offer.title)}
        </Heading>
        <Paragraph>
          <MarkdownRender source={offer.description} />
        </Paragraph>
      </Box>

      <VitrineGrid
        combinedFeatures={getCardFeatures(offer.items)}
        offerItems={sortedOfferItems}
      />

      {offer.extra && (
        <Caption my={5} textAlign="center" level={2}>
          <MarkdownRender source={offer.extra} />
        </Caption>
      )}

      {offerHtmlContent.bottom && (
        <Box mt={10}>
          <HtmlRenderer
            offerHtmlContent={offerHtmlContent.bottom}
            position="bottom"
          />
        </Box>
      )}

      {renewalOffer && (
        <Dialog
          show={isOfferBannerDialogOpen}
          onClose={() => setIsOfferBannerDialogOpen(false)}
        >
          <OfferBanner
            layout="dialog"
            variant={renewalOffer.type === 'winback' ? 'winback' : 'renewal'}
            onClick={handleOfferBannerClick}
          />
        </Dialog>
      )}
    </WrapperWithScrollToTopButton>
  );
};

export default OfferOverview;
