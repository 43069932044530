import apiCall from './apiCall';
import sanitiseUrlPath from './sanitiseUrlPath';

const getOfferType = ({ id, parent, onSuccess, onError }) => {
  let urlSuffix = '';

  if (parent) urlSuffix = sanitiseUrlPath(parent);
  if (id) urlSuffix = `api/offeritems/SubscriptionType/${id}`;

  return apiCall(`${API_OFFER_SERVICE}/${urlSuffix}`, {
    method: 'GET',
    params: {
      'api-version': '2',
    },
    onSuccess: response => {
      if (onSuccess) {
        return onSuccess(response);
      }
      return response;
    },
    onError: error => {
      if (onError) {
        return onError(error);
      }
      return error;
    },
  });
};

export default getOfferType;
