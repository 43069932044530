import OfferBannerDialog from './OfferBannerDialog';
import OfferBannerInline from './OfferBannerInline';

const OfferBanner = ({ layout = 'inline', variant, onClick }) => {
  if (layout === 'dialog') {
    return <OfferBannerDialog variant={variant} onClick={onClick} />;
  }

  return <OfferBannerInline variant={variant} onClick={onClick} />;
};

export default OfferBanner;
