/* eslint-disable import/max-dependencies */
import isEmpty from 'lodash.isempty';
import { Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { getI18n, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { AUTHENTICATION_STATUS, useAuthentication } from '~/authentication';
import PageLoader from '~/components/PageLoader';
import { DEFAULT, FORMULA_ID, RENEWAL, VITRINE_PATHNAME, CHECKOUT30_BACK_URL } from '~/constants';
import { BANNERS, WARNINGS } from '~/constants/messages';
import { useGlobalContext } from '~/context/global';
import { useOfferContext } from '~/context/offer';
import { getSearchCode, getCheckout30BackUrl } from '~/utils/constants';
import getOffer from '~/utils/getOffer';
import getSiteConfiguration from '~/utils/getSiteConfiguration';
import goHome from '~/utils/goHome';

import Message from '../components/messages/Message';
import OfferDetail from './OfferDetail';
import OfferOverview from './OfferOverview';

const siteConfig = getSiteConfiguration(MH_BRAND, API_PREFIX);

const Offer = ({ defaultFlow = DEFAULT }) => {
  const { language: lang } = getI18n();

  const { authenticationStatus, userInfo } = useAuthentication();
  const { cmpLoaded, setGlobalState, staticRender } = useGlobalContext();
  const history = useHistory();
  const location = useLocation();
  const { state = {} } = useLocation();
  const { offer, setOfferState } = useOfferContext();
  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isRedirectedFromLuxAuth, setIsRedirectedFromLuxAuth] = useState(
    MH_GROUP === GROUPS.LUX,
  );

  const isRenewal = defaultFlow === RENEWAL;

  if (location.pathname === '/') {
    location.pathname = VITRINE_PATHNAME;
  }

  useEffect(() => {
    if (!staticRender) {
      if (authenticationStatus === AUTHENTICATION_STATUS.AUTHENTICATED) {
        setGlobalState({
          user: userInfo,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticRender, authenticationStatus]);

  useEffect(() => {
    const message = {
      type: BANNERS.INFO,
      text: WARNINGS[lang]?.OFFER_INVALID,
    };
    if (!isRedirectedFromLuxAuth) {
      setIsLoading(true);
      getOffer({
        id: location.pathname,
        onSuccess: response => {
          if (!response.active && response.slug !== VITRINE_PATHNAME) {
            goHome(history, {
              message,
            });
          } else {
            setOfferState(response);
            const checkout30BackUrl = getCheckout30BackUrl();
            if (checkout30BackUrl) {
              sessionStorage.setItem(CHECKOUT30_BACK_URL, checkout30BackUrl);
            }
          }
          setIsLoading(false);
        },
        onError: () => {
          if (isRenewal) {
            window.history.pushState({}, '', window.location.origin);
            return history.push('/access');
          }
          return goHome(history, {
            message,
          });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, history, i18n.language, isRedirectedFromLuxAuth]);

  // Lux logon redirect logic. If redirected from external logon, make sure to load user data and load previous formula
  useEffect(() => {
    if (MH_GROUP !== GROUPS.LUX) {
      return;
    }
    const activeFormulaId = localStorage.getItem(FORMULA_ID);
    const luxLogonCode = getSearchCode();
    if (activeFormulaId && luxLogonCode) {
      setIsRedirectedFromLuxAuth(true);
      history.push(`/checkout?formula_id=${activeFormulaId}`, {
        code: luxLogonCode,
      });
    } else {
      setIsRedirectedFromLuxAuth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isEmpty(offer) || !cmpLoaded) {
    return <PageLoader />;
  }

  return (
    <Fragment>
      {offer.metaTitle && (
        <Helmet>
          <title>{offer.metaTitle || siteConfig.title}</title>
          <meta
            property="og:title"
            content={offer.metaTitle || siteConfig.title}
          />
          <meta
            name="description"
            content={offer.metaDescription || siteConfig.description}
          />
          <meta
            property="og:description"
            content={offer.metaDescription || siteConfig.description}
          />
          {siteConfig.ogLocale && (
            <meta property="og:locale" content={siteConfig.ogLocale} />
          )}
          {siteConfig.ogSiteName && (
            <meta property="og:site_name" content={siteConfig.ogSiteName} />
          )}
          {siteConfig.ogImage && (
            <meta property="og:img" content={siteConfig.ogImage} />
          )}
          <meta property="og:type" content="article" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:description"
            content={offer.metaDescription || siteConfig.ogDescription}
          />
          <meta
            name="twitter:title"
            content={offer.metaTitle || siteConfig.ogTitle}
          />
          <meta
            name="og:url"
            content={`${siteConfig.ogUrl}${offer.slug ? `/${offer.slug}` : ''}`}
          />
          {MH_ENV !== ENVS.production && (
            <meta name="robots" content="noindex, nofollow" />
          )}
        </Helmet>
      )}

      {state.message && (
        <Message type={state.message.type} message={state.message.text} />
      )}
      {offer.items.find(item => item.type === 'SubscriptionType') ? (
        <OfferOverview defaultFlow={defaultFlow} />
      ) : (
        <OfferDetail defaultFlow={defaultFlow} />
      )}
    </Fragment>
  );
};

export default Offer;
