const formatPrice = price => {
  const formatedPrice =
    price &&
    price.toLocaleString('nl-BE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      minimumIntegerDigits: 1,
      maximumFractionDigits: 2,
    });

  return formatedPrice;
};

export default formatPrice;
