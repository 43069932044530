type Listener = (isVisible: boolean) => void;

let listeners: Array<Listener> = [];
let isListenerAttached = false;

export function registerPageVisibilityListener(cb: (isVisible: boolean) => void) {
  listeners.push(cb);

  if (!isListenerAttached) {
    isListenerAttached = true;
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        listeners.forEach((listener) => listener(true));
      } else {
        listeners.forEach((listener) => listener(false));
      }
    });
  }
}
