const base = {
  [LANG.NL]: {
    choose: 'Kies',
  },
  [LANG.DE]: {
    choose: 'Jetzt bestellen',
  },
  [LANG.EN]: {
    choose: 'Choose',
  },
  [LANG.FR]: {
    choose: 'Choisissez',
  },
  [LANG.PT]: {
    choose: 'Escolher',
  },
};

export default base;
