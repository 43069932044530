import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowUp } from '@mediahuis/chameleon-theme-wl/icons';
import { IconButton } from '@mediahuis/chameleon-react';

const ButtonWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

export const WrapperWithScrollToTopButton = ({ children }) => {
  const [buttonVisible, setButtonVisible] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 200 ? setButtonVisible(true) : setButtonVisible(false);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      {children}
      {buttonVisible && (
        <ButtonWrapper>
          <IconButton
            circular={true}
            appearance="primary"
            icon={ArrowUp}
            onClick={handleScrollToTop}
          />
        </ButtonWrapper>
      )}
    </Fragment>
  );
};
