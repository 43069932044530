import {
  Box,
  Button,
  Caption,
  Flex,
  Paper,
  Text,
} from '@mediahuis/chameleon-react';
import { ChevronForward } from '@mediahuis/chameleon-theme-wl/icons';

const DefaultVitrineCardBase = ({
  buttonCaption,
  buttonText,
  children,
  gap,
  highlight,
  highlightColor,
  label,
  testId,
  onClick,
}) => {
  return (
    <Flex flexDirection="column">
      <Box
        alignSelf="center"
        bgColor={highlight ? highlightColor : 'primaryBase'}
        height={6}
        px={8}
        py={2}
        style={{
          borderTopLeftRadius: 'var(--border-radius-2)',
          borderTopRightRadius: 'var(--border-radius-2)',
          fontWeight: 'bold',
          visibility: label ? 'visible' : 'hidden',
        }}
      >
        {label && (
          <Text fontFamily="system" size="Caption2" textAlign="center">
            {label}
          </Text>
        )}
      </Box>

      <Box
        borderRadius={3}
        height="full"
        style={{
          border: highlight
            ? `2px solid var(--${highlightColor})`
            : '2px solid transparent',
        }}
      >
        <Paper
          borderRadius={3}
          data-testid={testId}
          display="flex"
          elevation={2}
          flexDirection="column"
          hoverable
          height="full"
          style={{
            gap,
            paddingBottom: gap ? gap : '5%',
            paddingTop: gap ? gap : '5%',
          }}
          onClick={onClick}
        >
          {children}

          <Flex
            alignSelf="center"
            flexDirection="column"
            style={{ gap: '0.5rem', width: '85%' }}
          >
            <Button iconRight={ChevronForward}>{buttonText}</Button>

            {buttonCaption && (
              <Caption color="colorGrey60" textAlign="center">
                {buttonCaption}
              </Caption>
            )}
          </Flex>
        </Paper>
      </Box>
    </Flex>
  );
};

export default DefaultVitrineCardBase;
