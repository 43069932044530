import {
  getOriginVitrine,
  getSearchGoToUrl,
  getSessionStorageGoToUrl,
} from '~/utils/constants';

export const getGoBackPath = () => {
  const searchGoToUrl = getSearchGoToUrl();
  const sessionStorageGoToUrl = getSessionStorageGoToUrl();
  const originVitrine = getOriginVitrine();

  if (searchGoToUrl) {
    return searchGoToUrl;
  } else if (sessionStorageGoToUrl) {
    return sessionStorageGoToUrl;
  } else if (originVitrine) {
    return originVitrine;
  }

  return '/';
};
